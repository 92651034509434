import { SvgName } from '@/svg'

export type MenuItemProps = {
  title: string
  href: string
  startIcon?: SvgName
}

export const menu: MenuItemProps[] = [
  {
    title: 'Viver no Cabo Branco',
    href: '/#cabo-branco'
  },
  {
    title: 'Diferenciais',
    href: '/#diferenciais'
  },
  {
    title: 'Perspectivas',
    href: '/#perspectivas'
  },
  {
    title: 'Plantas',
    href: '/#plantas'
  },
  {
    title: 'Quem somos',
    href: '/#quem-somos'
  },
  {
    title: 'Quero saber mais',
    href: '/#tenho-interesse'
  }
]
