import { useMemo } from 'react'
import { hamburger, HamburgerVariantProps } from './hamburger.variants'

export type useHamburgerProps = HamburgerVariantProps & {
  className?: string
  ariaControls?: string
  toggle: () => void
}

export const useHamburger = (props: useHamburgerProps) => {
  const {
    className = '',
    active = false,
    theme = 'dark',
    ariaControls,
    toggle
  } = props

  const styles = useMemo(() => hamburger({ active, theme }), [active, theme])
  const ariaLabel = active ? 'Open menu' : 'Close menu'

  return {
    className,
    styles,
    active,
    toggle,
    ariaControls,
    ariaLabel
  }
}
