import { tv, VariantProps } from '@/utils/tv'

export const hamburger = tv({
  slots: {
    base: [
      'flex place-items-center shrink-0',
      'w-7 h-7',
      'p-0.5',
      'rounded-md',
      'border-none shadow-none outline-none appearance-none',
      'cursor-pointer'
    ],
    bar: [
      'relative',
      'h-0.5',
      'before:content[""] before:absolute before:h-full before:-top-2',
      'after:content[""] after:absolute after:h-full after:-bottom-2',

      'block w-full rounded-sm',
      'transition duration-300 ease-out',

      'before:block before:w-full before:rounded-sm',
      'before:transition before:duration-300 before:ease-out',

      'after:block after:w-full after:rounded-sm',
      'after:transition after:duration-300 after:ease-out'
    ]
  },
  variants: {
    active: {
      true: {
        bar: [
          'rotate-45',
          'before:rotate-90 before:top-0',
          'after:rotate-90 after:bottom-0'
        ]
      }
    },
    theme: {
      light: {
        bar: ['bg-blue-500', 'before:bg-blue-500', 'after:bg-blue-500']
      },
      dark: {
        bar: ['bg-white', 'before:bg-white', 'after:bg-white']
      }
    }
  },
  defaultVariants: {
    active: false,
    theme: 'dark'
  }
})

export type HamburgerVariantProps = VariantProps<typeof hamburger>
