import { tv, VariantProps } from '@/utils/tv'

export const header = tv({
  slots: {
    base: [
      'fixed left-0 top-0 z-100',
      'w-full',
      'border-b border-b-yellow-300/30',
      'transition-colors duration-300'
    ],
    bar: [
      'relative',
      'flex items-center justify-between',
      'h-24',
      'px-6 lg:px-8'
    ],
    brandLink: [
      'flex',
      'relative z-50',
      'h-32 shrink-0',
      'mt-auto mr-16 -translate-y-0.5',
      'mobile:outline-none',
      'focus:outline-dotted focus:outline-gray-400/30 focus:outline-offset-6'
    ],
    brand: ['w-auto h-full', 'outline-none', 'drop-shadow-md'],
    nav: [
      'w-full',
      'flex flex-row items-center justify-between',
      'mobile:flex-col mobile:items-start mobile:gap-6 desktop:gap-4',
      'mobile:absolute mobile:z-40 mobile:left-0 mobile:top-full',
      'mobile:pt-20 mobile:pb-6 mobile:px-6',
      'mobile:transition-all mobile:duration-300',
      'mobile:h-[calc(100vh-80px)]',
      'mobile:h-[calc(100dvh-80px)]',
      'mobile:bg-green-700/95'
    ],
    group: ['mobile:relative', 'mobile:pb-14'],
    list: ['flex items-center', 'mobile:flex-col mobile:items-start', 'gap-8'],
    item: ['flex', 'mobile:w-full', 'desktop:mt-auto', 'mt-auto'],
    link: ['uppercase', 'tracking-[.20rem]']
  },
  variants: {
    opened: {
      false: {
        nav: ['mobile:-translate-x-full']
      },
      true: {
        base: ['bg-green-700/95'],
        nav: ['mobile:translate-x-0']
      }
    },
    blur: {
      true: {
        base: ['bg-green-700/95']
      }
    }
  },
  compoundVariants: [
    {
      blur: true,
      opened: false,
      className: {
        base: ['backdrop-blur-xl']
      }
    }
  ],
  defaultVariants: {
    opened: false,
    blur: false
  }
})

export type HeaderVariantProps = VariantProps<typeof header>

export type HeaderReturnVariantProps = ReturnType<typeof header>
