'use client'

import { Link } from '@/components/ui/link'
import { menu } from './header.menu'
import { HeaderReturnVariantProps } from './header.variants'
import { useHeaderState } from './use-header-state'

type HeaderNavProps = {
  styles: HeaderReturnVariantProps
}

const HeaderNav = ({ styles }: HeaderNavProps) => {
  const { linkSize } = useHeaderState()

  const nav = menu.slice(0, menu.length - 1)
  const conversion = menu.slice(menu.length - 1, menu.length)

  return (
    <nav className={styles.nav()}>
      <div className={styles.group({ className: 'w-full' })}>
        <ul
          className={styles.list({
            className: ['mobile:overflow-y-auto']
          })}
        >
          {nav.map(({ href, title }) => (
            <li key={title} className={styles.item()}>
              <Link
                className={styles.link()}
                href={href}
                title={title}
                color="light"
                size={linkSize}
              >
                {title}
              </Link>
            </li>
          ))}
        </ul>
      </div>
      <div
        className={styles.group({
          className: [
            'mobile:w-full',
            'desktop:before:content-[""]',
            'desktop:before:absolute desktop:before:top-0',
            'desktop:before:h-full desktop:before:w-[1px]',
            'desktop:before:bg-yellow-300/30'
          ]
        })}
      >
        <ul className={styles.list({ className: ['desktop:pl-10'] })}>
          {conversion.map(({ href, title }) => (
            <li key={title} className={styles.item()}>
              <Link
                className={styles.link()}
                href={href}
                title={title}
                color="light"
                size={linkSize}
              >
                <span>{title}</span>
              </Link>
            </li>
          ))}
        </ul>
      </div>
    </nav>
  )
}

export default HeaderNav
