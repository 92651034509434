import(/* webpackMode: "eager", webpackExports: ["default"] */ "/vercel/path0/node_modules/next/dist/client/script.js");
;
import(/* webpackMode: "eager" */ "/vercel/path0/node_modules/next/font/local/target.css?{\"path\":\"src/fonts/index.ts\",\"import\":\"\",\"arguments\":[{\"src\":[{\"path\":\"./circula/circula-medium.woff2\",\"weight\":\"400\"}],\"display\":\"swap\",\"variable\":\"--font-circula\"}],\"variableName\":\"circula\"}");
;
import(/* webpackMode: "eager" */ "/vercel/path0/node_modules/next/font/google/target.css?{\"path\":\"src/fonts/index.ts\",\"import\":\"Mulish\",\"arguments\":[{\"subsets\":[\"latin\"],\"display\":\"swap\",\"variable\":\"--font-mulish\",\"weight\":[\"400\",\"500\",\"600\",\"700\"]}],\"variableName\":\"mulish\"}");
;
import(/* webpackMode: "eager" */ "/vercel/path0/node_modules/keen-slider/keen-slider.min.css");
;
import(/* webpackMode: "eager", webpackExports: ["Toaster"] */ "/vercel/path0/node_modules/sonner/dist/index.mjs");
;
import(/* webpackMode: "eager", webpackExports: ["HeaderProvider"] */ "/vercel/path0/src/components/common/header/header.context.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["default"] */ "/vercel/path0/src/components/common/header/header.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["default"] */ "/vercel/path0/src/components/ui/link/link.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["default"] */ "/vercel/path0/src/components/ui/vector/vector.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["default"] */ "/vercel/path0/src/components/util/google-analytics/google-analytics-tracking.tsx");
;
import(/* webpackMode: "eager" */ "/vercel/path0/src/styles/globals.css");
