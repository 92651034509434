/* eslint-disable react-hooks/exhaustive-deps */
import { useCallback, useContext, useEffect } from 'react'
import { useParams, usePathname } from 'next/navigation'
import { useMotionValueEvent, useScroll } from 'framer-motion'
import { useWindowSize } from 'react-use'
import { HeaderContext, HeaderStateProps } from './header.context'

const DESKTOP_MAX_SCREEN = 1025

export const useHeaderState = () => {
  const pathname = usePathname()
  const params = useParams()

  const { state, setState } = useContext(HeaderContext)
  const { width } = useWindowSize()
  const { scrollY } = useScroll()
  const { opened, blur } = state

  const dispatch = useCallback(
    (payload: Partial<HeaderStateProps>) => {
      setState((state) => ({ ...state, ...payload }))
    },
    [state]
  )

  const onToggleMenu = useCallback(
    (opened: boolean) => dispatch({ opened }),
    [dispatch]
  )

  const closeMenu = () => {
    opened && onToggleMenu(false)
  }

  useMotionValueEvent(scrollY, 'change', (latest) => {
    if (opened) return

    if (latest >= 60 && !blur) {
      dispatch({ blur: true })
    } else if (latest < 60 && blur) {
      dispatch({ blur: false })
    }
  })

  useEffect(() => {
    const params: Partial<HeaderStateProps> = {}

    if (width >= DESKTOP_MAX_SCREEN) {
      if (opened) {
        params.opened = false
      }

      params.linkSize = 'md'
    } else {
      params.linkSize = 'lg'
    }

    dispatch(params)
  }, [width])

  useEffect(() => {
    dispatch({ opened: false })
  }, [pathname, params])

  return {
    ...state,
    dispatch,
    onToggleMenu,
    closeMenu
  }
}
