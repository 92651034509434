'use client'

import { usePreventScroll } from 'react-aria'
import { Hamburger } from '@/components/ui/hamburguer'
import HeaderBrand from './header.brand'
import HeaderNav from './header.nav'
import { useHeader } from './use-header'
import { useHeaderState } from './use-header-state'

const Header = () => {
  const { headerRef, styles } = useHeader()
  const { opened, onToggleMenu } = useHeaderState()

  usePreventScroll({ isDisabled: !opened })

  return (
    <header ref={headerRef} className={styles.base()}>
      <div className={styles.bar()}>
        <HeaderBrand styles={styles} />
        <HeaderNav styles={styles} />
        <Hamburger
          active={opened}
          className="desktop:hidden"
          toggle={() => onToggleMenu(!opened)}
          ariaControls="header-nav"
        />
      </div>
    </header>
  )
}

export default Header
