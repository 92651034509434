'use client'

import { LinkProps } from '@/components/ui/link'
import {
  Dispatch,
  ReactNode,
  SetStateAction,
  createContext,
  useState
} from 'react'

export type HeaderStateProps = {
  opened: boolean
  blur: boolean
  linkSize: LinkProps['size']
}

type HeaderContextProps = {
  state: HeaderStateProps
  setState: Dispatch<SetStateAction<HeaderStateProps>>
}

type HeaderProviderProps = {
  children: ReactNode
}

const initialHeaderState: HeaderStateProps = {
  opened: false,
  blur: false,
  linkSize: 'md'
}

export const HeaderContext = createContext<HeaderContextProps>({
  state: initialHeaderState,
  setState: () => {}
})

export const HeaderProvider = ({ children }: HeaderProviderProps) => {
  const [state, setState] = useState(initialHeaderState)

  return (
    <HeaderContext.Provider value={{ state, setState }}>
      {children}
    </HeaderContext.Provider>
  )
}
